import React from 'react'
import _ from 'lodash'

import TextGroup, { Image } from '../Texts'
import jose from '../../assets/images/about-us/Jose.webp'
import tiago from '../../assets/images/about-us/Tiago.webp'
import arrow from '../../assets/images/arrow-small-blue.svg'

const AboutFour = ({ lang }) => {
  const isEn = lang === 'en';
  return (
    <section id="about-four" className="content container">
      <div className="row">
        <TextGroup
          rootProps={{ className: 'col-12 heading-xlarge' }}
          title={{
            type: 'h2',
            text: isEn ? 'Leadership' : 'A direção',
          }}
        />
        <div className="row people">
        <div className="off-1 col-5 col-12-medium row nowrap">
            <div className="image col-6-medium ">
              <img src={jose} alt="José Ruivo" loading="lazy" />
            </div>
            <div className='col-6-medium person-info'>
              <h2>José Ruivo</h2>
              <h3>CEO</h3>
              <a href="https://www.linkedin.com/in/josemiguelruivo/" target="_blank" className="linkedin-link">
                Linkedin
                <Image image={arrow} width={13} height={13} />
              </a>
            </div>
          </div>
          <div className="col-5 col-12-medium row nowrap">
            <div className="image col-6-medium">
              <img src={tiago} alt="Tiago Jacob" loading="lazy" />
            </div>
            <div className='col-6-medium person-info'>
              <h2>Tiago Jacob</h2>
              <h3>COO</h3>
              <a href="https://www.linkedin.com/in/tiagojacob/" target="_blank" className="linkedin-link">
                Linkedin
                <Image image={arrow} width={13} height={13} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default AboutFour
