import React from 'react'
import _ from 'lodash'

import c_logo1 from '../../assets/images/company-logos/hp-white.svg'
import c_logo2 from '../../assets/images/company-logos/nos-white.svg'
import c_logo3 from '../../assets/images/company-logos/ericsson-white.svg'
import c_logo4 from '../../assets/images/company-logos/sonae-white.svg'
import c_logo5 from '../../assets/images/company-logos/sportzone-white.svg'
import c_logo6 from '../../assets/images/company-logos/worten-white.svg'
import c_logo7 from '../../assets/images/company-logos/ageas-white.svg'
import c_logo8 from '../../assets/images/company-logos/bynd-white.svg'
import office3 from '../../assets/images/about-us/office-3.webp'
import { Image } from '../Texts'
import { AboutBlock } from '../Blocks'
import { Desktop, Mobile } from '../MediaQueries'




const AboutThree = ({ lang }) => {
  const isEn = lang === 'en';
  const AboutBlockEntry = {
    rootProps: { className: 'tile row bg-blue heading-xlarge' },
    image: { rootProps: { className: 'col-6 col-12-medium image' }, image: office3 },
    textGroup: {
      rootProps: { className: 'col-6 col-12-medium last-text' },
      title: {
        type: 'h2',
        text: isEn ? 'We are\ntrusted by' : 'Marcas que\nconfiam em nós',
      },
    },
  };

  const companies = [
    {
      image: c_logo1,
      title: 'HP',
    },
    {
      image: c_logo2,
      title: 'NOS',
    },
    {
      image: c_logo3,
      title: 'ERICSSON',
    },
    {
      image: c_logo4,
      title: 'SONAE',
    },
    {
      image: c_logo5,
      title: 'SPORTZONE',
    },
    {
      image: c_logo6,
      title: 'WORTEN',
    },
    {
      image: c_logo7,
      title: 'Ageas',
    },
    {
      image: c_logo8,
      title: 'bynd',
    },
  ]

  return (
    <section id="about-three" className="bg-blue">
      <div className="content container">
        <Desktop>
          <AboutBlock rev={0} {...AboutBlockEntry} />
        </Desktop>
        <Mobile>
          <AboutBlock rev={0} {...AboutBlockEntry} image={''} />
        </Mobile>
        <div className="row">
          <div className="company-images">
            {_.map(companies, (company, i) => (
              <React.Fragment key={`${company.title}-${i}`}>
                <Image {...company} />
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
export default AboutThree
