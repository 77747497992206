import React from 'react'
import _ from 'lodash'

import TextGroup from '../Texts'

const AboutOne = ({ lang }) => {
const isEn = lang === 'en';
  return (
    <section id="about-one" className="content container">
      <TextGroup
        rootProps={{ className: 'align-center' }}
        title={{
          type: 'h1',
          text: isEn ? 'We are your go-to team for\nimpactful tech solutions.' : 'Somos a sua equipa especialista em soluções tecnológicas impactantes.',
        }}
      />
    </section>
  )
}
export default AboutOne
