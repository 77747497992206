import React from 'react'
import _ from 'lodash'

import { AboutBlock } from '../Blocks'

import office1 from '../../assets/images/about-us/office-1.webp'
import office2 from '../../assets/images/about-us/office-2.webp'


const AboutTwo = ({ lang }) => {
  const isEn = lang === 'en';

  const AboutBlocks = [
    {
      rootProps: { className: 'tile row' },
      image: { rootProps: { className: 'col-6 col-12-medium image' }, image: office1 },
      textGroup: {
        rootProps: { className: 'text-group col-6 col-12-medium' },
        title: {
          type: 'h2',
          text:
            isEn ? 'Our major expertise? Adaptability.\nOur secret sauce? Our people.\nOur focus? The client.' : 'A nossa especialidade? Adaptação.\nO nosso ingrediente secreto? As nossas pessoas.\nO nosso foco? O cliente.',
        },
        description: {
          text:
            isEn ? "Founded in 2001, log is a tech partner that continuously reinvents itself to add value to its clients' businesses through impactful technology.\n\n We're acclaimed as one of the major experts in WordPress, but what differentiates us from our competitors is that we're constantly focused on our client's bottom line and always open to adapt to their specific needs." : "Fundada em 2001, a log é um parceiro tecnológico que se reinventa constantemente para agregar valor aos negócios dos seus clientes através de tecnologia de impacto.\nSomos considerados dos maiores especialistas em WordPress, mas o que nos distingue dos nossos concorrentes é o foco constante nos resultados dos nossos clientes, estando sempre abertos a adaptar-nos às suas necessidades.",
        },
      },
    },
    {
      rootProps: { className: 'tile row' },
      image: { rootProps: { className: 'col-6 col-12-medium image' }, image: office2 },
      textGroup: {
        rootProps: { className: 'text-group col-6 col-12-medium middle-text' },
        description: {
          text:
            isEn ? "Since day 1, we've been strong advocates of open source technologies and promoters of good practices — from being one of the first tech partners with an in-house user experience team to incorporating an agile mindset and methodologies. And we keep evolving." : 'Sendo um dos primeiros parceiros tecnológicos com uma equipa interna de User Experience, temos sempre defendido as tecnologias open source e a promoção de boas práticas, incluindo uma mentalidade e metodologias ágeis. E continuamos a evoluir.',
        },
      },
    },
  ]

  return (
    <section id="about-two">
      <div className="content container">
        <div className="row">
          {_.map(AboutBlocks, (block, i) => (
            <React.Fragment key={`about-block-${i}`}>
              <AboutBlock rev={i % 2} {...block} />
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  )
}
export default AboutTwo
