import React from 'react'

import Layout from '../components/layout'
import AboutOne from '../components/about-us/AboutOne'
import AboutThree from '../components/about-us/AboutThree'
import AboutTwo from '../components/about-us/AboutTwo'
import AboutFour from '../components/about-us/AboutFour'

const Work = props => (
  <Layout lang="en" title="About Us" meta={{description: 'A tech partner that continuously reinvents itself to add value to its clients\' businesses through impactful technology.'}} rootProps={{ className: 'page about-us' }}>
    <AboutOne lang="en" />
    <AboutTwo lang="en" />
    <AboutThree lang="en" />
    <AboutFour lang="en" />
  </Layout>
)

export default Work
